<header>
  <div class="container">

    <span class="openBtn" onclick="openNav()">&#9776;</span>

    <h1 class="title"><a routerLink="/">Samuel Witz</a></h1>

    <nav class="topNav">
      <a routerLink="/">Accueil</a>
      <a routerLink="/photos">Photos</a>
      <a routerLink="/videos">Vidéos</a>
      <a routerLink="/about">A propos</a>
    </nav>

    <div class="social-media">
      <a href="https://www.instagram.com/_samovitch_" target="_blank" rel="noopener noreferrer"><i class="fa fa-fw fa-instagram"></i></a>
      <a href="https://fr.linkedin.com/in/samuel-witz-05b0561b9?trk=public_profile_browsemap" target="_blank" rel="noopener noreferrer"><i class="fa fa-fw fa-linkedin-square"></i></a>
      <a href="mailto: productionwitz@gmail.com"><i class="fa fa-fw fa-envelope"></i></a>
    </div>

  </div>

  <div id="navbar" class="overlay">
    <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
    <div class="overlay-content">
      <a routerLink="/" onclick="closeNav()">Accueil</a>
      <a routerLink="/photos" onclick="closeNav()">Photos</a>
      <a routerLink="/videos" onclick="closeNav()">Vidéos</a>
      <a routerLink="/about" onclick="closeNav()">A propos</a>
    </div>
  </div>
</header>
