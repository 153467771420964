<section class="container">
  <div class="presentation-text">
    <h1>Biographie</h1>
    <p>Passionné par la capture d'instants précieux et de paysages époustouflants, je suis photographe et vidéaste depuis plusieurs années.<br><br>
      Mon parcours a débuté avec un Nikon D3300, me permettant d'explorer la photographie et la vidéo. Fasciné par les perspectives aériennes, j'ai ensuite acquis un drone DJI Mavic Air 2, puis un Lumix G90, me dotant ainsi d'outils performants pour concrétiser mes visions artistiques.<br><br>
      Aujourd'hui, j'utilise un drone DJI Mavic Air 2 S, me permettant de capturer des images et vidéos aériennes d'une qualité intéressante. Ma GoPro Hero 7, accompagnée de ses nombreux accessoires, complète mon arsenal pour saisir les moments d'action et d'intensité.<br><br>
      Je réalise une multitude de projets, tant personnels que professionnels pour des entreprises, mettant mon expertise au service de leurs besoins en communication visuelle.<br><br>
      Mes domaines de prédilection incluent la nature, les événements automobiles et les paysages. J'ai à cœur de créer des images et des vidéos qui captivent et inspirent.</p>
  </div>
  <div class="presentation-image">
    <img src="./assets/photos/presentation.jpg" alt="presentation-image">
  </div>
</section>
