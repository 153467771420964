import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {AboutComponent} from './about/about.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  { path: 'photos',
    loadChildren: () =>
      import('./photos/photos.module').then(
        m => m.PhotosModule
      )
  },
  { path: 'videos',
    loadChildren: () =>
      import('./videos/videos.module').then(
        m => m.VideosModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
